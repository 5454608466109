import { generatePath } from 'react-router'

import { Button } from '@/components/atoms/Button'
import { Panel, PanelTitle } from '@/components/atoms/Panel'
import { DescribedImage } from '@/components/molecules/DescribedImage'
import { routes } from '@/config'
import { useVaultWithSuspense } from '@/hooks'
import { PreviewCharts } from '@/pages/VaultPages/PreviewPage/components'
import type { ApyModes, VaultForList } from '@/types'
import { getNetworkImage } from '@vaultsfyi/ui'

interface VaultsListRowDetailsProps {
  vault: VaultForList
  apyMode: ApyModes
}

export const VaultChartsItem = ({ vault, apyMode }: VaultsListRowDetailsProps) => {
  const { address, network, name } = vault
  const vaultData = useVaultWithSuspense({
    address,
    chainName: network,
    interval: apyMode,
  })

  return (
    <Panel className="border border-gray shadow-none [&+&]:mt-6">
      <PanelTitle>
        <Button variant="text" className="mb-4 underline" asChild>
          <a href={generatePath(routes.vaultPreview, { address, chainName: network })}>
            {name} {address}
            <DescribedImage src={getNetworkImage(network)} alt={`${network} network image`} loading="lazy">
              <span className="first-letter:uppercase">{network}</span>
            </DescribedImage>
          </a>
        </Button>
      </PanelTitle>
      <PreviewCharts vault={vaultData} apyMode={apyMode} />
    </Panel>
  )
}
