import { DEFAULT_TVL_IN_USD_RANGE } from '@/config/search'
import { TVL_LOWER_BOUND_KEY, TVL_UPPER_BOUND_KEY } from '@/constants/stateConstants'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router'

export function useTvlSearchParams() {
  const [searchState] = useSearchParams()

  const tvlLowerBound = Number(searchState.get(TVL_LOWER_BOUND_KEY) ?? DEFAULT_TVL_IN_USD_RANGE[0])
  const tvlUpperBound = Number(searchState.get(TVL_UPPER_BOUND_KEY) ?? DEFAULT_TVL_IN_USD_RANGE[1])
  const selectedTvlRange: [number, number] = useMemo(
    () => [tvlLowerBound, tvlUpperBound],
    [tvlLowerBound, tvlUpperBound]
  )

  return { selectedTvlRange }
}
