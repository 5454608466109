import { useEffect, useMemo, useState } from 'react'

import { Button } from '@/components/atoms/Button'
import { SaveIcon, UndoIcon } from '@/components/atoms/Icons'
import { Textarea } from '@/components/atoms/Textarea'
import { Tile } from '@/components/atoms/Tile'
import { DescribedImage } from '@/components/molecules/DescribedImage'
import { LabeledField } from '@/components/molecules/LabeledField'
import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'
import { trpc } from '@/config/trpc'
import { PreviewYieldSource } from '@/pages/VaultPages/PreviewPage/components'
import { validateTemplateString } from '@/utils/vaultUtils/parseVaultDescription'
import type { Asset } from '@vaultsfyi/common'
import { getProtocolImage } from '@vaultsfyi/ui'

interface DataChangerProps {
  selectedProtocol: { name: string; product: string | null; version: string | null; yieldSource: string | null }
}

export function DataChanger({ selectedProtocol }: DataChangerProps) {
  const { name, product, version, yieldSource } = selectedProtocol
  const { mutateAsync } = trpc.updateProtocolData.useMutation()

  const [newYieldSource, setNewYieldSource] = useState<string | undefined | null>(yieldSource)

  useEffect(() => {
    setNewYieldSource(yieldSource)
  }, [yieldSource])

  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleChangeDataClick = async () => {
    try {
      setButtonDisabled(true)
      validateTemplateString(newYieldSource ?? '')
      const { status } = await mutateAsync({
        name,
        product,
        version,
        yieldSource: newYieldSource,
      })
      if (status === 'Success') {
        window.alert('Changed yield source successfully')
      }
    } catch (e) {
      window.alert(e)
    } finally {
      setButtonDisabled(false)
    }
  }

  const vaultData = useMemo(
    () =>
      newYieldSource && {
        yieldSource: newYieldSource,
        asset: {
          name: 'ASSET_NAME',
          symbol: 'ASSET_SYMBOL',
          decimals: 18,
          assetAddress: '0x1',
        } as Asset,
      },
    [newYieldSource]
  )

  return (
    <div className="flex basis-full flex-col gap-6">
      <div className="grid grid-cols-[repeat(auto-fill,148px)] gap-6">
        <div>
          <LabeledValueHeader>Protocol</LabeledValueHeader>
          <LabeledValueContent>
            <LabeledValueValue>
              <DescribedImage src={getProtocolImage(name)} alt={`${name} image`} loading="lazy">
                <span className="first-letter:uppercase">{name}</span>
              </DescribedImage>
            </LabeledValueValue>
          </LabeledValueContent>
        </div>
        <div>
          <LabeledValueHeader>Product</LabeledValueHeader>
          <LabeledValueContent>
            <LabeledValueValue>{product}</LabeledValueValue>
          </LabeledValueContent>
        </div>
        {version ? (
          <div>
            <LabeledValueHeader>Version</LabeledValueHeader>
            <LabeledValueContent>
              <LabeledValueValue>{version}</LabeledValueValue>
            </LabeledValueContent>
          </div>
        ) : null}
      </div>
      <LabeledField
        id="protocolYieldSource"
        label="Default Yield Source"
        headerContent={
          <Button
            variant="icon"
            onClick={() => setNewYieldSource(yieldSource)}
            title="Reset protocol default yield source"
          >
            <UndoIcon />
          </Button>
        }
      >
        <Textarea
          id="protocolYieldSource"
          name="protocolYieldSource"
          rows={4}
          autoCorrect="off"
          value={newYieldSource ?? ''}
          onChange={(e: any) => setNewYieldSource(e.target.value)}
        />
      </LabeledField>
      {vaultData && (
        <>
          Preview:
          <Tile>
            <PreviewYieldSource vault={vaultData} />
          </Tile>
        </>
      )}
      <Button className="mt-auto" onClick={handleChangeDataClick} disabled={buttonDisabled}>
        <SaveIcon size={24} />
        Change data
      </Button>
    </div>
  )
}
