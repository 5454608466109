import { cn } from '@/utils/cn'
import type { HTMLAttributes } from 'react'
import { tooltipStyles } from '../Tooltip'

export const ChartTooltip = ({ className, children, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn(tooltipStyles, 'bg-white/80 text-text-secondary')} data-role="chart-tooltip" {...props}>
      {children}
    </div>
  )
}
