import { cn } from '@/utils/cn'
import type { InputHTMLAttributes } from 'react'

export const Input = ({ className, ...props }: InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <input
      className={cn(
        'h-10 rounded-lg border border-gray bg-white px-4 py-0 text-text-primary leading-none outline-none transition-all',
        'placeholder:text-text-secondary',
        'hover:border-primary focus-visible::border-primary',
        className
      )}
      autoComplete="off"
      autoCorrect="off"
      spellCheck="false"
      {...props}
    />
  )
}
