import { Loader, Page } from '@/components/molecules'
import { usePageTracking } from '@/hooks'
import type { BackendVault } from '@/types'
import { Suspense } from 'react'
import { Await, useLoaderData } from 'react-router'
import { ChartExplorer } from './ChartExplorer'

function ChartExplorerPage() {
  const { vaults } = useLoaderData() as { vaults: BackendVault[] }
  usePageTracking()

  return (
    <Page title="Chart Explorer">
      <section className="flex flex-col gap-4">
        <Suspense fallback={<Loader />}>
          <Await resolve={vaults}>
            {(vaults) => (
              <>
                <h1 className="text-center font-normal text-xl leading-5 sm:text-3xl">Chart Explorer</h1>
                <ChartExplorer vaults={vaults} />
              </>
            )}
          </Await>
        </Suspense>
      </section>
    </Page>
  )
}

export const Component = () => ChartExplorerPage()
