import { QueryClientProvider } from '@tanstack/react-query'
import type { ReactNode } from 'react'
import { HelmetProvider } from 'react-helmet-async'

import { BookmarksProvider } from './BookmarksProvider'
import { StyleProvider } from './StyleProvider'

import { TooltipProvider } from '@/components/atoms/Tooltip'
import { queryClient } from '@/config/queryClient'
import { trpc, trpcClient } from '@/config/trpc'

interface ProvidersProps {
  children: ReactNode
}

export const Providers = ({ children }: ProvidersProps) => {
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <BookmarksProvider>
          <HelmetProvider>
            <TooltipProvider>
              <StyleProvider>{children}</StyleProvider>
            </TooltipProvider>
          </HelmetProvider>
        </BookmarksProvider>
      </QueryClientProvider>
    </trpc.Provider>
  )
}
