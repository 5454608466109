import { Button } from '@/components/atoms/Button'
import { StarIcon } from '@/components/atoms/Icons'
import { Panel, PanelContentWrapper, PanelTitle } from '@/components/atoms/Panel'
import { routes } from '@/config'
import { NavLink } from 'react-router'

export const BookmarkedVaultsListFallback = () => {
  return (
    <Panel className="p-4 sm:px-8 sm:py-6">
      <PanelContentWrapper>
        <PanelTitle>No favorite vaults found...</PanelTitle>
        <div>
          <p>
            It seems that you haven't added any vaults to your favorite list yet. To add vaults to your favorites, click
            the <StarIcon className="-translate-y-1 inline-block text-text-secondary" size={24} /> bookmark button next
            to the vault you'd like to save.
          </p>
          <p className="mt-4">
            Find more vaults on the{' '}
            <Button variant="text" className="underline" asChild>
              <NavLink to={routes.vaults}>vaults list</NavLink>
            </Button>{' '}
            or{' '}
            <Button variant="text" className="underline" asChild>
              <NavLink to={routes.optimizer}>explore some vaults</NavLink>
            </Button>{' '}
            that's right for you.
          </p>
        </div>
      </PanelContentWrapper>
    </Panel>
  )
}
