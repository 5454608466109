import * as ToggleGroup from '@radix-ui/react-toggle-group'
import type { ComponentPropsWithoutRef } from 'react'

import type { ApyModes } from '@/types'
import { cn } from '@/utils/cn'

interface ApySelectGroupProps {
  value: ApyModes
  setValue: (val: ApyModes) => void
}

export function ApySelectGroup({
  value,
  setValue,
  className,
  ...props
}: ApySelectGroupProps & ComponentPropsWithoutRef<'div'>) {
  const onToggleValueChange = (value: string) => {
    value && setValue(value as ApyModes)
  }

  return (
    <div className={cn('flex flex-wrap items-center justify-end gap-2', className)} {...props}>
      <span>Show APY as avg. of</span>
      <ToggleGroup.Root
        className="relative isolate flex h-10 items-center gap-1 rounded-lg bg-primary/5 p-1"
        type="single"
        defaultValue="center"
        value={value}
        onValueChange={onToggleValueChange}
      >
        <GroupItem value="1day">1 day</GroupItem>
        <GroupItem value="7day">7 days</GroupItem>
        <GroupItem value="30day">30 days</GroupItem>
      </ToggleGroup.Root>
    </div>
  )
}

const GroupItem = ({ children, value, ...props }: ToggleGroup.ToggleGroupItemProps) => {
  return (
    <ToggleGroup.Item
      className={cn(
        'relative flex h-full items-center justify-center rounded border border-transparent p-1 transition-colors sm:p-2',
        '[-webkit-text-stroke-color:transparent] [-webkit-text-stroke:0em]',
        'aria-[checked=true]:border-primary aria-[checked=true]:bg-primary/15',
        'hover:text-primary focus-visible:text-primary'
      )}
      value={value}
      {...props}
    >
      {children}
    </ToggleGroup.Item>
  )
}
