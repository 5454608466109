import { truncate } from '../valueUtils'

type TruncableChartData<T extends string, U = any> = { [key in T]: number } & U

export function truncateChartData<T extends string, U>(
  chartData: TruncableChartData<T, U>[],
  deltaKey: T,
  truncInterval: number
): TruncableChartData<T, U>[] {
  const truncatedData: TruncableChartData<T, U>[] = []
  for (let i = 0; i < chartData.length; i++) {
    const lastDataPoint = truncatedData[truncatedData.length - 1]
    const dataPoint = chartData[i]
    const truncatedDate = truncate(dataPoint[deltaKey], truncInterval)
    if (!lastDataPoint || lastDataPoint[deltaKey] !== truncatedDate) {
      truncatedData.push({ ...dataPoint, [deltaKey]: truncatedDate })
    }
  }
  return truncatedData
}
