import { Button } from '@/components/atoms/Button'
import { Image } from '@/components/atoms/Image'
import { TableCell } from '@/components/atoms/Table'
import { routes } from '@/config'
import { PROTOCOLS } from '@/constants/protocols'
import type { VaultForList } from '@/types'
import { getProtocolImage } from '@vaultsfyi/ui'
import { NavLink, generatePath } from 'react-router'

interface ProtocolCellProps extends Pick<VaultForList, 'protocolName'> {
  testId?: string
}

const getProtocolPagePath = (protocolName: string) => {
  if (PROTOCOLS.includes(protocolName)) {
    const hasWww = window.location.host.startsWith('www')
    const host = hasWww ? window.location.host.slice(4) : window.location.host
    return `${window.location.protocol}//${protocolName}.${host}`
  }
  return generatePath(routes.protocol, { protocolName })
}

export const ProtocolNameCell = ({ protocolName, testId }: ProtocolCellProps) => {
  const protocolPage = getProtocolPagePath(protocolName)
  return (
    <TableCell data-testid={`${testId}-protocol-name`} data-testvalue={protocolName}>
      <Button
        className="flex max-w-full items-center gap-2 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
        variant="text"
        data-testid={`${testId}-name-link`}
        asChild
      >
        <NavLink to={protocolPage}>
          <Image
            src={getProtocolImage(protocolName)}
            alt={`${protocolName} protocol image`}
            title={`${protocolName} protocol image`}
            loading="lazy"
          />
          <span className="first-letter:uppercase">{protocolName}</span>
        </NavLink>
      </Button>
    </TableCell>
  )
}
