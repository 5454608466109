import { Suspense } from 'react'
import { Await, useLoaderData } from 'react-router'

import { trackPortfolioEvents } from '../hooks'
import { BookmarkedVaultsList } from './BookmarkedVaultsList'
import { BookmarkedVaultsListHero } from './BookmarkedVaultsListHero'

import { Panel, PanelTitle } from '@/components/atoms/Panel'
import { ApySelectGroup } from '@/components/molecules/ApySelectGroup'
import { APY_MODE_KEY } from '@/constants/stateConstants'
import { useVaultsSearchParams } from '@/hooks/list/useVaultsSearchParams'
import type { BackendVault } from '@/types'
import { getSubdomain } from '@/utils/subdomain/getSubdomain'

export const BookmarkedVaults = () => {
  const { vaults } = useLoaderData() as { vaults: BackendVault[] }
  const subdomain = getSubdomain()
  const { updateSearchState, apyMode } = useVaultsSearchParams()

  return (
    <section className="flex w-full max-w-full flex-col gap-4">
      <BookmarkedVaultsListHero />
      <div className="flex flex-wrap-reverse items-center justify-end gap-x-4 gap-y-2">
        <ApySelectGroup
          value={apyMode}
          setValue={(value) => {
            updateSearchState([APY_MODE_KEY, value])
            trackPortfolioEvents({ action: 'APY range changed', label: `${value} range selected` })
          }}
        />
      </div>
      <Suspense fallback={<Fallback />}>
        <Await resolve={vaults}>
          {(vaults) => <BookmarkedVaultsList vaults={vaults} subdomain={subdomain} apyMode={apyMode} />}
        </Await>
      </Suspense>
    </section>
  )
}

function Fallback() {
  return (
    <Panel>
      <PanelTitle>Loading your favorite vaults...</PanelTitle>
    </Panel>
  )
}
