import { Button } from '@/components/atoms/Button'
import { Dropdown, DropdownContent, DropdownList, DropdownListItem, DropdownTrigger } from '@/components/atoms/Dropdown'
import { Label } from '@/components/atoms/Label'
import type { ChartPeriod } from '@/utils'
import { CHART_PERIODS } from '@/utils'
import { cn } from '@/utils/cn'

interface SelectPeriodProps {
  period: ChartPeriod
  setPeriod: (period: ChartPeriod) => void
}

export const SelectChartPeriod = ({ period, setPeriod }: SelectPeriodProps) => {
  return (
    <Dropdown>
      <Label>
        Chart time range
        <DropdownTrigger className="min-w-24">{period}</DropdownTrigger>
        <DropdownContent>
          <DropdownList className="gap-2">
            {CHART_PERIODS.map((chartPeriod) => (
              <SelectItem
                key={chartPeriod}
                period={chartPeriod}
                setPeriod={setPeriod}
                isActive={chartPeriod === period}
              />
            ))}
          </DropdownList>
        </DropdownContent>
      </Label>
    </Dropdown>
  )
}

interface SelectItemProps {
  isActive: boolean
}

const SelectItem = ({ isActive, period, setPeriod }: SelectPeriodProps & SelectItemProps) => {
  return (
    <DropdownListItem>
      <Button
        variant="default"
        className={cn('leading-none hover:text-primary focus-visible:text-primary', isActive && 'font-bold')}
        onClick={() => setPeriod(period)}
      >
        {period}
      </Button>
    </DropdownListItem>
  )
}
