import type { DbWarning } from '@vaultsfyi/common'
import { parseBips } from '@vaultsfyi/common'

import { WarningIcon } from '@/components/atoms/Icons'
import { TableCell, type TableCellProps } from '@/components/atoms/Table'
import { Tooltip, TooltipContent } from '@/components/atoms/Tooltip'
import { ValueIndicator, getValueIndicatorView } from '@/components/atoms/ValueIndicator'
import type { VaultForList } from '@/types'
import { formatNumber, getVaultUnexpiredWarnings, isUndefined } from '@/utils'

interface ApyCellProps extends Pick<VaultForList, 'apy'> {
  testId?: string
  warnings: DbWarning[]
}

export const ApyCell = ({ apy, warnings, testId, ...props }: ApyCellProps & TableCellProps) => {
  const unexpiredWarnings = getVaultUnexpiredWarnings(warnings)
  const apyValue = parseBips(apy)
  const formattedApy = isUndefined(apyValue) ? 'n/a' : `${formatNumber(apyValue, 2, 2)}%`

  return (
    <TableCell align="right" data-cell="APY" {...props}>
      <div className="flex items-center gap-2">
        {unexpiredWarnings.length > 0 && (
          <Tooltip className="text-warning">
            <WarningIcon size={24} />
            <TooltipContent>
              <ApyCellWarningsTooltip warnings={warnings} />
            </TooltipContent>
          </Tooltip>
        )}
        <ValueIndicator variant={getValueIndicatorView(apy)}>
          <span className="font-bold" data-testid={`${testId}-apy`}>
            {formattedApy}
          </span>
        </ValueIndicator>
      </div>
    </TableCell>
  )
}

const ApyCellWarningsTooltip = ({ warnings }: Pick<VaultForList, 'warnings'>) => {
  return (
    <div className="max-h-60 max-w-60 space-y-3 overflow-auto">
      {warnings.map(({ id, content }) => (
        <p key={id}>{content}</p>
      ))}
    </div>
  )
}
