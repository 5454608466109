import { Button } from '@/components/atoms/Button'
import { Container } from '@/components/atoms/Container'
import { DescribedImage } from '@/components/molecules/DescribedImage'
import { routes } from '@/config'
import { VAULTS_FYI_URL } from '@/constants'
import type { Subdomain } from '@/types/Subdomain'
import { SubdomainType } from '@/types/Subdomain'
import { trackAllVaultsClickEvent } from '@/utils'
import { getSubdomainImage } from '@/utils/subdomain'

interface Props {
  subdomain: Subdomain
}

export const SubdomainBanner = ({ subdomain }: Props) => {
  const allVaultsUrl = VAULTS_FYI_URL + routes.vaults.replace('/', '')
  const subdomainTypeText = getSubdomainTypeText(subdomain)

  return (
    <div className="w-full py-1">
      <Container>
        <div className="text-center text-text-secondary">
          Only{' '}
          <DescribedImage
            src={getSubdomainImage(subdomain)}
            className="inline-flex gap-1 align-bottom [&_[data-role=image-wrapper]]:min-w-4"
            alt={`${subdomain.name} ${subdomainTypeText} image`}
            title={subdomain.name}
            loading="lazy"
          >
            <span className="first-letter:uppercase">{subdomain.name}</span>
          </DescribedImage>
          {` ${subdomainTypeText} is being shown. `}
          <span className="whitespace-nowrap">
            <Button
              variant="text"
              className="inline-block underline"
              onClick={() => trackAllVaultsClickEvent(subdomain.name)}
              asChild
            >
              <a href={allVaultsUrl} target="_blank" rel="noopener noreferrer">
                See all vaults
              </a>
            </Button>
            .
          </span>
        </div>
      </Container>
    </div>
  )
}

function getSubdomainTypeText(subdomain: Subdomain) {
  switch (subdomain.type) {
    case SubdomainType.Network:
      return 'chain'
    case SubdomainType.Protocol:
      return 'protocol'
  }
}
