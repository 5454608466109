import { useCallback } from 'react'
import type { URLSearchParamsInit } from 'react-router'
import { useSearchParams } from 'react-router'

export type SearchStateEntries = Array<[key: string, value: any]>

export function useSearchState(defaultParams?: URLSearchParamsInit) {
  const [value, setValue] = useSearchParams(defaultParams)

  const updateSearchState = useCallback(
    (...entries: Array<[key: string, value: any]>) => {
      setValue(
        (previousState) => {
          const newState = new URLSearchParams(previousState)
          for (const [key, value] of entries) {
            if (value === undefined || value === null || value === '') {
              newState.delete(key)
              continue
            }

            if (Array.isArray(value)) {
              newState.delete(key)
              for (const item of value) {
                newState.append(key, String(item))
              }
            } else {
              newState.set(key, String(value))
            }
          }
          return newState
        },
        { replace: true }
      )
    },
    [setValue]
  )

  return { searchState: value, updateSearchState } as const
}
