import { useEffect, useState } from 'react'

import { Button } from '@/components/atoms/Button'
import { CheckIcon, MinusCircleIcon, PlusCircleIcon } from '@/components/atoms/Icons'
import { Input } from '@/components/atoms/Input'
import { LabeledField } from '@/components/molecules/LabeledField'
import { LabeledInputField } from '@/components/molecules/LabeledInputField'
import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'
import { VaultTag } from '@/components/molecules/VaultTag'
import { trpc } from '@/config/trpc'
import type { BackendTag } from '@/types'

interface TagEditorProps {
  setVaultTags: (tags: BackendTag[]) => void
  vaultTags: BackendTag[]
}

export function TagEditor({ vaultTags, setVaultTags }: TagEditorProps) {
  const [tags] = trpc.getAllTags.useSuspenseQuery()
  const { mutateAsync: addTag } = trpc.addTag.useMutation()
  const { mutateAsync: updateTag } = trpc.updateTag.useMutation()
  const { mutateAsync: deleteTag } = trpc.removeTag.useMutation()

  const [selectedColor, setTagColor] = useState('')
  const [tagName, setTagName] = useState('')

  const [selectedTag, setSelectedTag] = useState<number | undefined>(undefined)

  useEffect(() => {
    const tag = tags.find((t) => t.id === selectedTag)
    if (tag) {
      setTagColor(tag.color)
      setTagName(tag.name)
    } else {
      setTagColor('')
      setTagName('')
    }
  }, [selectedTag, tags])

  const saveTag = () => {
    const tagColor = selectedColor || '#000000'
    if (selectedTag) {
      return updateTag({
        tagName,
        tagColor,
        tagId: selectedTag,
      })
    } else {
      return addTag({
        tagName,
        tagColor,
      })
    }
  }

  const handleSave = async () => {
    try {
      const res = await saveTag()
      window.alert(res['message'])
    } catch (e) {
      window.alert(e)
    }
  }

  const handleDelete = async () => {
    try {
      if (selectedTag) {
        const res = await deleteTag({
          tagId: selectedTag,
        })
        setSelectedTag(undefined)
        setVaultTags(vaultTags.filter((vaultTag) => vaultTag.id !== selectedTag))
        window.alert(res['message'])
      } else {
        window.alert('No tag selected')
      }
    } catch (e) {
      window.alert(e)
    }
  }

  const handleTagClick = (tag: BackendTag) => {
    setSelectedTag(tag.id)
    const tagInVaultTags = vaultTags.find((vaultTag) => vaultTag.id === tag.id)
    const newTags = tagInVaultTags ? vaultTags.filter((vaultTag) => vaultTag.id !== tag.id) : [...vaultTags, tag]
    setVaultTags(newTags)
  }

  return (
    <div className="flex flex-col gap-4">
      <div>
        <LabeledValueHeader>Tags list</LabeledValueHeader>
        <LabeledValueContent className="[font-size:unset]">
          <ul className="flex flex-wrap gap-2">
            {tags.map((tag) => (
              <Button
                variant="default"
                className="rounded-2xl ring-primary hover:ring-2 focus-visible:ring-2"
                key={tag.id}
                onClick={() => handleTagClick(tag)}
              >
                <VaultTag tag={tag} />
              </Button>
            ))}
          </ul>
        </LabeledValueContent>
      </div>
      <div>
        <LabeledValueHeader>Tag ID</LabeledValueHeader>
        <LabeledValueContent className="[font-size:unset]">
          <LabeledValueValue>
            <p>{selectedTag ?? '-'}</p>
          </LabeledValueValue>
        </LabeledValueContent>
      </div>
      <LabeledInputField
        id="tagName"
        label="Tag name"
        placeholder="Select tag from above"
        value={tagName}
        onChange={(e) => setTagName(e.target.value)}
      />
      <LabeledField id="tagColor" label="Tag color">
        <Input
          className="min-w-10 cursor-pointer p-1 [&::-webkit-color-swatch-wrapper]:p-0 [&::-webkit-color-swatch]:rounded-md [&::-webkit-color-swatch]:border-none"
          id="tagColor"
          name="tagColor"
          type="color"
          onChange={(e) => setTagColor(e.target.value)}
          value={selectedColor}
        />
      </LabeledField>
      <div className="flex flex-col gap-4 rounded-lg bg-white p-2 [&_button_span]:w-full">
        <Button variant="secondary" onClick={handleSave}>
          <CheckIcon />
          <span>{selectedTag ? 'Update tag' : 'Save tag'}</span>
        </Button>
        <Button variant="secondary" onClick={() => setSelectedTag(undefined)}>
          <PlusCircleIcon />
          <span>New tag</span>
        </Button>
        <Button variant="secondary" onClick={handleDelete}>
          <MinusCircleIcon />
          <span>Delete tag</span>
        </Button>
      </div>
    </div>
  )
}
