import * as Sentry from '@sentry/react'
import { createBrowserRouter, redirect } from 'react-router'

import { getAllVaults } from './queries/getAllVaults'

import { outdatedRoutes, routes } from '@/config'
import { ErrorPage, FallbackPage, Layout } from '@/pages'
import * as AboutPage from '@/pages/AboutPage/AboutPage'
import * as ChangeAboutPage from '@/pages/ChangeAboutPage/ChangeAboutPage'
import * as ChangeProtocolDataPage from '@/pages/ChangeProtocolDataPage/ChangeProtocolDataPage'
import * as ChangeVaultDataPage from '@/pages/ChangeVaultDataPage/ChangeVaultDataPage'
import * as ChartExplorerPage from '@/pages/ChartExplorerPage/ChartExplorerPage'
import * as PortfolioPage from '@/pages/PortfolioPage/PortfolioPage'
import * as ProtocolPage from '@/pages/ProtocolPage/ProtocolPage'
import * as ProtocolsListPage from '@/pages/ProtocolsListPage/ProtocolsListPage'
import * as TermsOfServicePage from '@/pages/TermsOfServicePage/TermsOfServicePage'
import * as VaultPreviewPage from '@/pages/VaultPages/PreviewPage/PreviewPage'
import * as VaultsListPage from '@/pages/VaultsListPage/VaultsListPage'
import { WallbreakerChartsPage } from '@/pages/WallbreakerChartsPage/WallbreakerChartsPage'
import * as WallbreakerLogin from '@/pages/WallbreakerLogin/WallbreakerLogin'
import * as WallbreakerPage from '@/pages/WallbreakerPage/WallbreakerPage'
import * as YieldOptimizerPage from '@/pages/YieldOptimizerPage/YieldOptimizerPage'
import { getProtocol } from './queries/getProtocol'
import { checkIsAuthorized } from './utils/checkIsAuthorized'

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter) as typeof createBrowserRouter

export const router = sentryCreateBrowserRouter([
  {
    element: <Layout />,
    errorElement: <ErrorPage />,
    hydrateFallbackElement: <FallbackPage />,
    children: [
      {
        path: routes.index,
        async loader({ request }) {
          const url = new URL(request.url)
          return redirect(routes.vaults + url.search)
        },
      },
      {
        path: routes.vaults,
        Component: VaultsListPage.Component,
        async loader({ request }) {
          return {
            vaults: getAllVaults(request.url),
            protocol: getProtocol(),
          }
        },
      },
      {
        path: routes.optimizer,
        Component: YieldOptimizerPage.Component,
        async loader({ request }) {
          return { vaults: getAllVaults(request.url) }
        },
      },
      {
        path: routes.chartExplorer,
        Component: ChartExplorerPage.Component,
        async loader({ request }) {
          return { vaults: getAllVaults(request.url) }
        },
      },
      {
        path: routes.favorites,
        Component: PortfolioPage.Component,
        async loader({ request }) {
          return { vaults: getAllVaults(request.url) }
        },
      },
      {
        path: routes.vaultPreview,
        Component: VaultPreviewPage.Component,
      },
      {
        path: routes.wallbreaker,
        Component: WallbreakerPage.Component,
        loader: async () => await checkIsAuthorized(),
      },
      {
        path: routes.wallbreakerLogin,
        Component: WallbreakerLogin.Component,
      },
      {
        path: routes.dataUpdater,
        Component: ChangeVaultDataPage.Component,
        loader: async () => await checkIsAuthorized(),
      },
      {
        path: routes.protocolUpdater,
        Component: ChangeProtocolDataPage.Component,
        loader: async () => await checkIsAuthorized(),
      },
      {
        path: routes.aboutChange,
        Component: ChangeAboutPage.Component,
        loader: async () => await checkIsAuthorized(),
      },
      {
        path: routes.about,
        Component: AboutPage.Component,
      },
      {
        path: routes.termsOfService,
        Component: TermsOfServicePage.Component,
      },
      {
        path: routes.wallbreakerCharts,
        Component: WallbreakerChartsPage,
        async loader({ request }) {
          await checkIsAuthorized()
          return { vaults: getAllVaults(request.url) }
        },
      },
      {
        path: routes.protocols,
        Component: ProtocolsListPage.Component,
        async loader({ request }) {
          return { vaults: getAllVaults(request.url) }
        },
      },
      {
        path: routes.protocol,
        Component: ProtocolPage.Component,
      },
      {
        path: outdatedRoutes.yieldExplorer,
        loader() {
          return redirect(routes.optimizer)
        },
      },
      {
        path: outdatedRoutes.explore,
        loader() {
          return redirect(routes.optimizer)
        },
      },
      {
        path: outdatedRoutes.vaultsList,
        loader() {
          return redirect(routes.vaults)
        },
      },
      {
        path: outdatedRoutes.portfolio,
        loader() {
          return redirect(routes.favorites)
        },
      },
    ],
  },
])
