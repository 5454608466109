import { cn } from '@/utils/cn'
import type { HTMLAttributes } from 'react'

export const Panel = ({ className, children, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <section className={cn('relative overflow-hidden rounded-2xl bg-white px-8 py-6 shadow', className)} {...props}>
      {children}
    </section>
  )
}

export const PanelHeader = ({ className, children, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <header className={cn('flex flex-wrap justify-between gap-x-4 gap-y-2', className)} {...props}>
      {children}
    </header>
  )
}

export const PanelContentWrapper = ({ className, children, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn('flex flex-col gap-6', className)} {...props}>
      {children}
    </div>
  )
}

export const PanelTitle = ({ className, children, ...props }: HTMLAttributes<HTMLHeadingElement>) => {
  return (
    <h4 className={cn('leading-tight', className)} {...props}>
      {children}
    </h4>
  )
}
