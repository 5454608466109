import { type HTMLAttributeAnchorTarget, type MouseEventHandler, type ReactNode, forwardRef } from 'react'
import { NavLink, useMatch } from 'react-router'

import { Button } from '@/components/atoms/Button'
import { routes } from '@/config'
import { trackMenuLinkEvent } from '@/utils'
import { cn } from '@/utils/cn'

export interface MenuLinkBasicProps {
  icon: ReactNode
  name: string
  to: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
  target?: HTMLAttributeAnchorTarget
  isIndex?: boolean
}

export interface MenuLinksBasicProps {
  links: MenuLinkBasicProps[]
  title: string
  icon: ReactNode
  expandable?: boolean
}

export const menuLinkStyles = cn(
  'flex h-10 justify-start gap-2 rounded-lg border border-transparent bg-transparent px-2 font-normal text-text-primary lg:justify-center',
  'hover:border-transparent hover:bg-transparent hover:text-primary focus-visible:border-transparent focus-visible:bg-transparent focus-visible:text-primary',
  'md:data-[active=true]:border-primary md:data-[active=true]:bg-primary/15 md:data-[active=true]:text-text-primary',
  'data-[active=true]:border-transparent data-[active=true]:bg-transparent data-[active=true]:text-primary'
)

export const MenuLink = forwardRef<HTMLButtonElement, MenuLinkBasicProps & { className?: string }>(
  ({ icon, name, to, onClick, target, isIndex, className }, ref) => {
    const isIndexActive = !!useMatch(routes.index)
    const isLinkActive = !!useMatch(to)
    const isIndicatorVisible = isIndex ? isIndexActive || isLinkActive : isLinkActive

    const handleMenuLinkClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
      trackMenuLinkEvent(name)
      onClick?.(e)
    }

    return (
      <Button ref={ref} variant="secondary" className={cn(menuLinkStyles, className)} asChild>
        <NavLink to={to} target={target} onClick={(e) => handleMenuLinkClick(e)} data-active={isIndicatorVisible}>
          {icon}
          <span>{name}</span>
        </NavLink>
      </Button>
    )
  }
)
