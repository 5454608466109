import { OptimizerVaultItemPlaceholder } from '../OptimizerVaultItem'

import { BlurOverlay } from '@/components/atoms/BlurOverlay'
import { ArrowIcon } from '@/components/atoms/Icons'
import { DEFAULT_APY_MODE } from '@/pages/YieldOptimizerPage/defaultValues'
import { cn } from '@/utils/cn'
import type { ReactNode } from 'react'

const period = 1_000

const placeholderStyles = cn('relative [clip-path:inset(0_round_1rem)]')

export function InvestmentOptimizerFallback() {
  return (
    <div className="flex flex-col gap-4">
      <div className={placeholderStyles}>
        <OptimizerVaultItemPlaceholder apyMode={DEFAULT_APY_MODE} period={period} />
        <PlaceholderBlurOverlay>
          <ArrowIcon className="repeat-infinite mt-16 animate-floating-arrow md:mt-0" size={16} />
          <span className="block font-bold text-2xl text-text-primary">
            Enter your criteria
            <br />
            to see results
          </span>
        </PlaceholderBlurOverlay>
      </div>
      <div className={placeholderStyles}>
        <OptimizerVaultItemPlaceholder apyMode={DEFAULT_APY_MODE} period={period} />
        <PlaceholderBlurOverlay />
      </div>
      <div className={placeholderStyles}>
        <OptimizerVaultItemPlaceholder apyMode={DEFAULT_APY_MODE} period={period} />
        <PlaceholderBlurOverlay />
      </div>
    </div>
  )
}

const PlaceholderBlurOverlay = ({ children }: { children?: ReactNode }) => {
  return (
    <BlurOverlay className="absolute inset-0 flex flex-col items-center justify-start p-4 text-center md:justify-center">
      {children}
    </BlurOverlay>
  )
}
