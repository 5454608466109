import { Cell, Label, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'

import { ChartContainer } from '@/components/atoms/Charts/ChartContainer'
import { ChartWatermark } from '@/components/atoms/Charts/ChartWatermark'
import type { ProportionsResult } from '@/utils'
import { formatNumber, formatUnits } from '@/utils'
import type { Asset } from '@vaultsfyi/common/types'
import { AssetDetailsChartTooltip } from './AssetDetailsChartTooltip'

interface VaultProportionsChartProps {
  chartData: ProportionsResult[]
  asset: Asset
  tvlInToken: string | undefined
  tvlInUsd: number | undefined
  tvlInNativeAsset: boolean | undefined
}

const CHART_OUTER_RADIUS = 112
const CHART_INNER_RADIUS = CHART_OUTER_RADIUS * 0.75

export const AssetDetailsChart = ({
  chartData,
  asset,
  tvlInToken,
  tvlInUsd,
  tvlInNativeAsset,
}: VaultProportionsChartProps) => {
  const renderableTvlInToken = tvlInToken
    ? `${formatNumber(formatUnits(BigInt(tvlInToken), asset.decimals))} ${asset.symbol}`
    : 'N/A'
  const renderableTvlInUsd = tvlInUsd ? `$${formatNumber(tvlInUsd)}` : 'N/A'
  const tvlToRender = tvlInNativeAsset ? renderableTvlInToken : renderableTvlInUsd

  return (
    <div className="relative mx-auto flex w-fit items-center justify-center">
      <ChartContainer className="aspect-square min-h-56 w-56">
        <ResponsiveContainer>
          <PieChart>
            <defs>
              {chartData.map(({ name, colors }) => (
                <linearGradient key={name} id={name} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={colors[0]} stopOpacity={1} />
                  <stop offset="100%" stopColor={colors[1]} stopOpacity={1} />
                </linearGradient>
              ))}
            </defs>
            <Pie
              data={chartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={CHART_OUTER_RADIUS}
              innerRadius={CHART_INNER_RADIUS}
              dataKey="value"
              isAnimationActive={false}
            >
              {chartData.map(({ name }, index) => (
                <Cell key={`cell-${index}`} fill={`url(#${name})`} stroke="transparent" />
              ))}
              <Label
                content={({ viewBox }) => {
                  if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                    return (
                      <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle" dominantBaseline="middle">
                        <tspan x={viewBox.cx} y={viewBox.cy} className="fill-text-primary font-bold text-2xl">
                          {tvlToRender}
                        </tspan>
                        <tspan x={viewBox.cx} y={(viewBox.cy || 0) + 24}>
                          Current TVL
                        </tspan>
                      </text>
                    )
                  }
                }}
              />
            </Pie>
            <Tooltip
              content={<AssetDetailsChartTooltip tvlInNativeAsset={tvlInNativeAsset} asset={asset} />}
              payload={chartData}
            />
          </PieChart>
        </ResponsiveContainer>
        <ChartWatermark variant="circle" />
      </ChartContainer>
    </div>
  )
}
