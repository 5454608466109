import { useMemo, useState } from 'react'

import {
  FormattedNumberInput,
  NumberInput,
  SelectFilterByGroup,
  type SetSelectedItemsType,
} from '@/components/molecules'
import { DAYS_IN_100_YEARS, INVESTMENT_PANEL_TOUCHED, ONE_BILLION } from '@/constants'
import { useIsMobile } from '@/hooks/useIsMobile'
import { getLocalStorageValue, groupTokens, setLocalStorageValue } from '@/utils'
import { cn } from '@/utils/cn'
import { getTokenImage } from '@vaultsfyi/ui'

interface InvestmentAmountProps {
  setAmount: (amount: number | undefined) => void
}

interface InvestmentPeriodProps {
  setPeriod: (event: number | undefined) => void
}

export type InvestmentPickerInputsProps = InvestmentAmountProps &
  InvestmentPeriodProps & {
    amount: number | undefined
    period: number | undefined
    assetSymbols: string[]
    selectedTokens: string[]
    setSelectedTokens: SetSelectedItemsType
  }

export const InvestmentPickerInputs = ({
  amount,
  setAmount,
  assetSymbols,
  selectedTokens,
  setSelectedTokens,
  period,
  setPeriod,
}: InvestmentPickerInputsProps) => {
  const isMobile = useIsMobile()
  const groupedAssets = useMemo(() => groupTokens(assetSymbols), [assetSymbols])

  const [isTouched, setTouched] = useState<boolean>(Boolean(getLocalStorageValue(INVESTMENT_PANEL_TOUCHED)))

  const handleTouch = () => {
    setLocalStorageValue(INVESTMENT_PANEL_TOUCHED, true)
    setTouched(true)
  }

  return (
    <div className="flex max-w-full flex-grow flex-col flex-wrap gap-3 sm:flex-row sm:items-center">
      <div className="flex max-w-full items-center gap-3 sm:grid sm:grid-cols-[auto,1fr]">
        <FormattedNumberInput
          className={cn('w-full sm:w-32', !isTouched && 'repeat-infinite animate-input-glow')}
          placeholder={isMobile ? 'Amount' : 'Enter amount'}
          value={amount}
          setValue={setAmount}
          maxValue={ONE_BILLION}
          onFocus={handleTouch}
        />
        <SelectFilterByGroup
          className={cn('w-full sm:w-52', !isTouched && 'repeat-infinite animate-input-glow')}
          groupedItems={groupedAssets}
          selectedItems={selectedTokens}
          setSelectedItems={setSelectedTokens}
          name="token"
          dropdownPrefix="Select"
          imageGetter={getTokenImage}
          onTriggerClick={handleTouch}
        />
      </div>
      <div className="flex flex-grow items-center gap-2 sm:flex-wrap">
        <span>for</span>
        <NumberInput
          className={cn('w-full sm:w-20', !isTouched && 'repeat-infinite animate-input-glow')}
          placeholder="some"
          value={period}
          setValue={setPeriod}
          maxValue={DAYS_IN_100_YEARS}
          onFocus={handleTouch}
        />
        <span>days</span>
      </div>
    </div>
  )
}
