import { Suspense } from 'react'
import { Await, useLoaderData, useParams } from 'react-router'

import { VaultsChartList } from './components'

import { Panel, PanelTitle } from '@/components/atoms/Panel'
import { Loader, Page } from '@/components/molecules'
import type { ApyModes, BackendVault } from '@/types'
import { isApyMode } from '@/types'

export function WallbreakerChartsPage() {
  const { vaults } = useLoaderData() as { vaults: BackendVault[] }

  const { apyMode } = useParams<{ apyMode: ApyModes }>()

  if (!isApyMode(apyMode)) {
    return (
      <Panel>
        <PanelTitle>Wrong APY mode</PanelTitle>
      </Panel>
    )
  }

  return (
    <Page title="All charts">
      <Suspense fallback={<Loader />}>
        <Await resolve={vaults}>{(vaults) => <VaultsChartList vaults={vaults} apyMode={apyMode} />}</Await>
      </Suspense>
    </Page>
  )
}
