import type { Network } from '@vaultsfyi/common'
import { Suspense, useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { Panel } from '@/components/atoms/Panel'
import { Loader, Page } from '@/components/molecules'
import { ApySelectGroup } from '@/components/molecules/ApySelectGroup'
import { APY_MODE_KEY } from '@/constants/stateConstants'
import { usePageTracking, useVaultWithSuspense } from '@/hooks'
import { useSearchState } from '@/hooks/useSearchState'
import {
  PreviewApyWarnings,
  PreviewCharts,
  PreviewHeader,
  PreviewStats,
  PreviewYieldSource,
} from '@/pages/VaultPages/PreviewPage/components'
import type { ApyModes } from '@/types'
import { isApyMode } from '@/types'
import { getVaultUnexpiredWarnings } from '@/utils'

type PreviewPagePathParams = {
  address: string
  chainName: Network
}

function usePathParams(): PreviewPagePathParams {
  const { address, chainName } = useParams<PreviewPagePathParams>()

  if (!address || !chainName) throw new Response('Page not found', { status: 404 })

  return { address, chainName } as const
}

interface PreviewPageProps {
  setPageName: (value: string) => void
}

function PreviewPage({ setPageName }: PreviewPageProps) {
  const { address, chainName } = usePathParams()
  const { updateSearchState, searchState } = useSearchState()
  const apyModeRaw = searchState.get(APY_MODE_KEY) ?? ''
  const apyMode: ApyModes = isApyMode(apyModeRaw) ? apyModeRaw : '7day'

  const vault = useVaultWithSuspense({ address, chainName, interval: apyMode })
  const { name, warnings, yieldSource } = vault

  const unexpiredWarnings = getVaultUnexpiredWarnings(warnings)

  useEffect(() => {
    setPageName(name)
  }, [name, setPageName])

  usePageTracking()

  return (
    <div className="flex w-full flex-col gap-6">
      <PreviewHeader vault={vault} />
      <ApySelectGroup value={apyMode} setValue={(value) => updateSearchState([APY_MODE_KEY, value])} />
      <PreviewStats vault={vault} apyMode={apyMode} />
      {unexpiredWarnings.length > 0 && <PreviewApyWarnings warnings={unexpiredWarnings} />}
      {yieldSource && (
        <Panel className="p-4 sm:p-6">
          <PreviewYieldSource vault={vault} />
        </Panel>
      )}
      <PreviewCharts vault={vault} apyMode={apyMode} />
    </div>
  )
}

export function Component() {
  const { chainName } = usePathParams()
  const [name, setName] = useState('')
  return (
    <Page title={`${name} vault on ${chainName} network`}>
      <Suspense fallback={<Loader />}>
        <PreviewPage setPageName={setName} />
      </Suspense>
    </Page>
  )
}
