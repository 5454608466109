import type { ReactNode } from 'react'

import { Button } from '@/components/atoms/Button'
import { BanknotesIcon, BlockIcon, DropIcon, MagnifyingGlassIcon } from '@/components/atoms/Icons'
import { BankIcon } from '@/components/atoms/Icons/BankIcon'
import { OVER_50_MLN_TVL_LOWER_BOUND } from '@/config/search'
import {
  SELECTED_TAGS_KEY,
  SELECTED_TOKENS_KEY,
  TVL_LOWER_BOUND_KEY,
  TVL_UPPER_BOUND_KEY,
} from '@/constants/stateConstants'
import type { SearchStateEntries } from '@/hooks/useSearchState'
import { cn } from '@/utils/cn'
import { STABLE_COINS } from '@vaultsfyi/common'
import { useSearchParams } from 'react-router'

interface FilterPresetProps {
  isIndex?: boolean
  entries: SearchStateEntries
  forbiddenEntries?: string[]
  icon: ReactNode
  label: string
}

const FILTER_PRESETS: FilterPresetProps[] = [
  {
    entries: [],
    icon: <MagnifyingGlassIcon />,
    label: 'All vaults',
  },
  {
    entries: [[TVL_LOWER_BOUND_KEY, OVER_50_MLN_TVL_LOWER_BOUND]],
    forbiddenEntries: [TVL_UPPER_BOUND_KEY],
    icon: <BankIcon />,
    label: 'TVL > $50M',
  },
  {
    entries: [[SELECTED_TAGS_KEY, 'Liquid Staking']],
    icon: <DropIcon />,
    label: 'Top LSTs',
  },
  {
    entries: [[SELECTED_TAGS_KEY, 'RWA']],
    icon: <BlockIcon />,
    label: 'Top RWAs',
  },
  {
    entries: [
      [SELECTED_TAGS_KEY, 'Lending'],
      [SELECTED_TOKENS_KEY, STABLE_COINS],
    ],
    icon: <BanknotesIcon />,
    label: 'Top USD Lending',
  },
]

export const FilterPresets = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const activePreset = findActivePreset(searchParams)

  return (
    <div className="mx-auto mt-2 flex w-fit flex-wrap gap-x-4 gap-y-2 px-4">
      {FILTER_PRESETS.map(({ entries, icon, label }, index) => (
        <Button
          key={label + index}
          variant="secondary"
          className={cn(
            'h-6 flex-grow border border-transparent bg-background',
            'hover:border-primary hover:text-primary focus-visible:border-primary focus-visible:text-primary',
            'data-[active=true]:border-primary data-[active=true]:bg-primary/15 data-[active=true]:text-primary'
          )}
          data-active={activePreset === index}
          onClick={() => setSearchParams(Object.fromEntries(entries))}
        >
          {icon}
          {label}
        </Button>
      ))}
    </div>
  )
}

function findActivePreset(searchState: URLSearchParams) {
  // Checking presets from reverse order to detect most exclusive ones first
  for (let i = FILTER_PRESETS.length - 1; i >= 0; i--) {
    if (
      FILTER_PRESETS[i].forbiddenEntries &&
      FILTER_PRESETS[i].forbiddenEntries?.some((entry) => searchState.getAll(entry).length > 0)
    ) {
      continue
    }
    const $isActive = FILTER_PRESETS[i].entries.every(
      ([key, value]) =>
        searchState.getAll(key).sort().toString() ===
        (Array.isArray(value) ? value.sort().toString() : value.toString())
    )
    if ($isActive) {
      return i
    }
  }
}
