import type { TextareaHTMLAttributes } from 'react'

import { cn } from '@/utils/cn'

export const Textarea = ({ className, ...props }: TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  return (
    <textarea
      className={cn(
        'rounded-lg border border-gray bg-white px-4 py-2 text-text-primary leading-tight outline-none transition-all',
        'placeholder:text-text-secondary',
        'hover:border-primary focus-visible::border-primary',
        className
      )}
      {...props}
    />
  )
}
