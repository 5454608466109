import { cn } from '@/utils/cn'
import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'
import { type ButtonHTMLAttributes, forwardRef } from 'react'

const buttonVariants = cva(cn('inline-flex gap-2 transition-colors'), {
  variants: {
    variant: {
      primary: cn(
        'inline-flex cursor-pointer gap-2 text-base outline-none transition-all',
        'h-10 items-center justify-center px-3 py-0 text-center leading-none',
        'rounded-lg bg-primary font-bold text-white',
        'hover:bg-primary-active focus-visible:bg-primary-active',
        'disabled:opacity-60'
      ),
      secondary: cn(
        'inline-flex cursor-pointer gap-2 text-base text-text-primary outline-none transition-all',
        'h-10 items-center justify-center px-3 py-0 text-center leading-none',
        'rounded-lg border border-blue bg-blue',
        'hover:border-text-primary focus-visible:border-text-primary',
        'disabled:border-blue disabled:text-text-secondary'
      ),
      text: cn(
        'inline-flex cursor-pointer gap-2 text-base text-primary outline-none transition-all',
        'hover:text-primary-active focus-visible:text-primary-active',
        'disabled:text-text-primary/60'
      ),
      icon: cn(
        'inline-flex cursor-pointer gap-2 text-base text-text-primary outline-none transition-all',
        'text-text-secondary',
        'hover:text-primary-active focus-visible:text-primary-active',
        'disabled:text-text-primary/60'
      ),
      default: cn(
        'inline-flex cursor-pointer gap-2 text-base text-text-primary outline-none transition-all',
        'hover:text-primary-active focus-visible:text-primary-active'
      ),
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
})

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ asChild = false, className, variant, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return <Comp className={cn(buttonVariants({ className, variant }))} ref={ref} {...props} />
  }
)
Button.displayName = 'Button'
