import arbitrum from '../images/networks/arbitrum.svg'
import base from '../images/networks/base.svg'
import gnosis from '../images/networks/gnosis.svg'
import mainnet from '../images/networks/mainnet.svg'
import optimism from '../images/networks/optimism.svg'
import polygon from '../images/networks/polygon.svg'

export const getNetworkImage = (network: string) => {
  switch (network.toLowerCase()) {
    case 'arbitrum':
      return arbitrum
    case 'base':
      return base
    case 'gnosis':
      return gnosis
    case 'mainnet':
      return mainnet
    case 'optimism':
      return optimism
    case 'polygon':
      return polygon
    default:
  }
}
