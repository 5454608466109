import { useEffect } from 'react'
import { generatePath } from 'react-router'

import { WarningIcon } from '@/components/atoms/Icons'
import { Tooltip, TooltipContent } from '@/components/atoms/Tooltip'
import { routes } from '@/config'
import { VAULTS_FYI_URL } from '@/constants'
import { formatNumber, trackEvent } from '@/utils'

interface Props {
  address: string
  network: string
  name: string
  amount: number | undefined
  percentage: number
  symbol: string
}

export const HighTvlImpactBadge = ({ amount, percentage, symbol, address, network, name }: Props) => {
  useEffect(() => {
    trackEvent({
      event: 'Yield Search',
      params: {
        name,
        link: VAULTS_FYI_URL + generatePath(routes.vaultPreview, { chainName: network, address }),
        network,
        address,
      },
    })
  }, [address, name, network])

  return (
    <span className="flex min-h-8 items-center justify-center bg-warning/30 px-4 py-2 text-center font-bold text-warning leading-none">
      <Tooltip className="hover:text-black focus-visible:text-black">
        <div className="flex w-fit items-center gap-1">
          <WarningIcon /> <span>APY Impact Warning</span>
        </div>
        <TooltipContent className="font-normal text-text-primary">
          <p>
            Lending {formatNumber(amount ?? 0)} {symbol} ({percentage.toFixed(2)}% of current TVL)
            <br />
            may negatively impact this vault’s returns.
          </p>
        </TooltipContent>
      </Tooltip>
    </span>
  )
}
