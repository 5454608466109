import { cn } from '@/utils/cn'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { type ComponentPropsWithoutRef, type ElementRef, type ReactNode, forwardRef, useState } from 'react'

export const TooltipProvider = TooltipPrimitive.Provider
TooltipProvider.displayName = TooltipPrimitive.Provider.displayName

const TooltipTrigger = ({ className, ...props }: TooltipPrimitive.TooltipTriggerProps) => (
  <TooltipPrimitive.Trigger
    className={cn(
      'flex size-fit cursor-help transition-colors hover:text-primary focus-visible:text-primary',
      className
    )}
    {...props}
  />
)
TooltipTrigger.displayName = TooltipPrimitive.Trigger.displayName

export const tooltipStyles = cn('bg-white p-3 shadow', 'rounded-md border border-gray', 'overflow-hidden')

export const TooltipContent = forwardRef<
  ElementRef<typeof TooltipPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      tooltipStyles,
      'z-50',
      'fade-in-0 zoom-in-95 data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 animate-in data-[state=closed]:animate-out',
      className
    )}
    {...props}
  />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

interface TooltipProps {
  children: [ReactNode, ReactNode]
  className?: string
  onClick?: TooltipPrimitive.TooltipTriggerProps['onClick']
  triggerAsChild?: boolean
  disabled?: boolean
}

export const Tooltip = ({ children, className, onClick, triggerAsChild, disabled }: TooltipProps) => {
  const [open, setOpen] = useState(false)

  function handleClick(event: any) {
    onClick?.(event)
    setOpen((prevState) => !prevState)
  }

  return (
    <TooltipPrimitive.Root open={open} onOpenChange={setOpen}>
      <TooltipTrigger onClick={handleClick} className={cn(className)} asChild={triggerAsChild} disabled={disabled}>
        {children[0]}
      </TooltipTrigger>
      {children[1]}
    </TooltipPrimitive.Root>
  )
}
Tooltip.displayName = TooltipPrimitive.Root.displayName
