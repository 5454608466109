import { Suspense } from 'react'
import { Await, useLoaderData } from 'react-router'

import { VaultsListFiltersTray } from './VaultsListFiltersTray'
import { VaultsListTable } from './VaultsListTable'

import { Panel, PanelTitle } from '@/components/atoms/Panel'
import { ApySelectGroup } from '@/components/molecules/ApySelectGroup'
import { MultiProtocolTvlHistoryChart } from '@/components/organisms/Charts/MultiProtocolTvlHistoryChart'
import { APY_MODE_KEY } from '@/constants/stateConstants'
import { useVaultsSearchParams } from '@/hooks'
import type { BackendProtocol, BackendVault } from '@/types'
import { DEFAULT_CHART_PERIOD } from '@/utils'
import { composeProtocolTvlChartData } from '@/utils/chartUtils/composeProtocolTvlChartData'
import { getSubdomain } from '@/utils/subdomain/getSubdomain'
import { trackVaultsListEvents } from '../hooks'

export const VaultsList = () => {
  const { vaults, protocol } = useLoaderData() as { vaults: BackendVault[]; protocol: BackendProtocol | null }
  const subdomain = getSubdomain()
  const {
    searchState,
    updateSearchState,
    apyMode,
    searchFor,
    selectedTags,
    selectedNetworks,
    selectedProtocols,
    selectedTokens,
    selectedTvlRange,
  } = useVaultsSearchParams()

  return (
    <section className="flex w-full max-w-full flex-col gap-4">
      <Suspense fallback={<ProtocolFallback />}>
        <Await resolve={protocol}>
          {(protocol) => {
            if (!protocol || !subdomain) return undefined
            const multiChartData = composeProtocolTvlChartData(protocol)
            return (
              <Panel className="p-4 sm:px-8 sm:py-6 [&_[data-role=chart-container]]:h-56 [&_[data-role=chart-container]]:min-h-56">
                <MultiProtocolTvlHistoryChart
                  protocolData={protocol}
                  multiChartData={multiChartData}
                  period={DEFAULT_CHART_PERIOD}
                />
              </Panel>
            )
          }}
        </Await>
      </Suspense>
      <Suspense fallback={<VaultsFallback />}>
        <Await resolve={vaults}>
          {(vaults) => (
            <>
              <VaultsListFiltersTray
                subdomain={subdomain}
                vaults={vaults}
                searchFor={searchFor}
                selectedTokens={selectedTokens}
                updateSearchState={updateSearchState}
              />
              <ApySelectGroup
                value={apyMode}
                setValue={(value) => {
                  updateSearchState([APY_MODE_KEY, value])
                  trackVaultsListEvents({ action: 'APY range changed', label: `${value} range selected` })
                }}
              />
              <VaultsListTable
                subdomain={subdomain}
                searchState={searchState}
                vaults={vaults}
                searchFor={searchFor}
                selectedTags={selectedTags}
                selectedNetworks={selectedNetworks}
                selectedProtocols={selectedProtocols}
                selectedTokens={selectedTokens}
                selectedTvlRange={selectedTvlRange}
                apyMode={apyMode}
              />
            </>
          )}
        </Await>
      </Suspense>
    </section>
  )
}

function VaultsFallback() {
  return (
    <Panel>
      <PanelTitle>Loading vaults data...</PanelTitle>
    </Panel>
  )
}

function ProtocolFallback() {
  return (
    <Panel>
      <PanelTitle>Loading protocol stats...</PanelTitle>
    </Panel>
  )
}
