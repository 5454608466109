import type { MenuLinkBasicProps, MenuLinksBasicProps } from '.'
import { MenuLink, menuLinkStyles } from '.'

import { Button } from '@/components/atoms/Button'
import { XIcon } from '@/components/atoms/Icons'
import { MenuIcon } from '@/components/atoms/Icons/MenuIcon'
import { ScrollArea } from '@/components/atoms/ScrollArea'
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetOverlay,
  SheetTrigger,
} from '@/components/atoms/Sheet'
import { VAULTS_FYI_TWITTER_URL } from '@/constants'
import { trackMenuLinkEvent } from '@/utils'
import { ExpandableLinkGroup } from './ExpandableLinkGroup'

interface Props {
  links: Array<MenuLinkBasicProps | MenuLinksBasicProps>
}

export const MobileNavigation = ({ links }: Props) => {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="icon" className="inline-block lg:hidden">
          <MenuIcon className="size-6" />
        </Button>
      </SheetTrigger>
      <SheetOverlay className="lg:hidden" />
      <SheetContent className="grid grid-rows-[auto_1fr_auto] lg:hidden">
        <SheetHeader>
          <SheetClose />
        </SheetHeader>
        <ScrollArea className="h-full">
          <div className="flex flex-col gap-2 px-4 py-2">
            {links.map((link, index) => {
              if ('links' in link) {
                if (link.expandable) {
                  return (
                    <ExpandableLinkGroup
                      links={link.links}
                      title={link.title}
                      icon={link.icon}
                      key={link.title + index}
                    />
                  )
                }
                return link.links.map((link) => <MenuLink key={link.name + index} {...link} />)
              }
              return <MenuLink key={link.name + index} {...link} />
            })}
          </div>
        </ScrollArea>
        <SheetFooter>
          <div className="flex gap-2">
            <Button className={menuLinkStyles} onClick={() => trackMenuLinkEvent('Twitter Link')} asChild>
              <a href={VAULTS_FYI_TWITTER_URL} target="_blank" rel="noopener noreferrer" title="vaults.fyi twitter">
                <XIcon size={20} />
              </a>
            </Button>
          </div>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}
