import { NavLink, useLocation } from 'react-router'

import { Button } from '@/components/atoms/Button'
import { Image } from '@/components/atoms/Image'
import { Logo } from '@/components/atoms/Logo'
import { routes } from '@/config/routes'
import { trackTitleLink } from '@/utils'
import { getSubdomain, getSubdomainImage } from '@/utils/subdomain'

export const HeaderTitle = () => {
  const { pathname } = useLocation()
  const shouldShowSubdomain = Boolean(getSubpageName(pathname))
  const subdomain = getSubdomain()

  return (
    <Button variant="default" onClick={trackTitleLink} asChild>
      <NavLink
        to={routes.index}
        className="flex items-center gap-2 text-text-primary transition-all hover:text-primary focus-visible:text-primary"
      >
        {subdomain ? (
          <Image className="size-6 rounded-none md:size-8" src={getSubdomainImage(subdomain)} />
        ) : (
          <Logo className="size-6 rounded-none md:size-8" />
        )}
        <span className="-translate-y-0.5 flex items-center gap-1 font-bold text-xl leading-none md:gap-2 md:text-2xl">
          <span>{subdomain && `${subdomain.prefix}.`}vaults.fyi</span>
          <span className="hidden md:block ">{shouldShowSubdomain && ` | ${getSubpageName(pathname)}`}</span>
        </span>
      </NavLink>
    </Button>
  )
}

function getSubpageName(pathname: string) {
  switch (pathname) {
    case routes.favorites:
      return 'Favorites'
    case routes.optimizer:
      return 'Optimizer'
    case routes.about:
      return 'About'
    case routes.termsOfService:
      return 'Terms'
    case routes.chartExplorer:
      return 'Charts'
    case routes.protocols:
      return 'Protocols'
  }
}
