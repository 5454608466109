import { VaultsListRow } from './VaultsListRow'
import { VaultsTableHeader } from './VaultsTableHeader'

import { ScrollArea, ScrollBar } from '@/components/atoms/ScrollArea'
import { Table, TableBody } from '@/components/atoms/Table'
import { useVaultsSortState } from '@/hooks'
import { useIsMobile } from '@/hooks/useIsMobile'
import type { ApyModes, VaultForList } from '@/types'
import type { Subdomain } from '@/types/Subdomain'

interface Props {
  vaults: VaultForList[]
  subdomain: Subdomain | undefined
  apyMode: ApyModes
}

export const VaultsTable = ({ vaults, subdomain, apyMode }: Props) => {
  const isMobile = useIsMobile()

  if (isMobile) {
    return <TableComponent vaults={vaults} subdomain={subdomain} apyMode={apyMode} />
  }

  return (
    <ScrollArea className="w-full rounded-2xl shadow">
      <TableComponent vaults={vaults} subdomain={subdomain} apyMode={apyMode} />
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  )
}

const TableComponent = ({ vaults, subdomain, apyMode }: Props) => {
  const { sortState, handleSetSortBy } = useVaultsSortState()

  return (
    <Table>
      <VaultsTableHeader setSortBy={handleSetSortBy} sortBy={sortState} subdomain={subdomain} apyMode={apyMode} />
      <TableBody className="flex flex-col gap-2 sm:table-row-group sm:gap-0">
        {vaults.map((vault, index) => (
          <VaultsListRow
            vault={vault}
            key={`${vault.network}-${vault.address}-${index}`}
            subdomain={subdomain}
            apyMode={apyMode}
          />
        ))}
      </TableBody>
    </Table>
  )
}
