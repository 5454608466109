import { BlurOverlay } from '@/components/atoms/BlurOverlay'
import { ArrowIcon } from '@/components/atoms/Icons'
import { PanelContentWrapper } from '@/components/atoms/Panel'
import { MultiApyChart } from '@/components/molecules/ApyChart/MultiApyChart'
import { MultiTvlChart } from '@/components/molecules/TvlChart/MultiTvlChart'
import { MultiApyHistoryChartHeader } from '@/components/organisms/Charts/MultiApyHistoryChart'
import { MultiTvlHistoryChartHeader } from '@/components/organisms/Charts/MultiTvlHistoryChart'
import { MULTI_APY_CHART_DATA_MOCK } from '@/constants/mocks/charts/multiApyChartMock'
import { MULTI_TVL_CHART_DATA_MOCK } from '@/constants/mocks/charts/multiTvlChartMock'
import type { ApyModes, BackendVault } from '@/types'
import type { MultiChartDataGroup } from '@/utils/chartUtils/composeMultiChartData'
import type { ReactNode } from 'react'

interface Props {
  apyMode: ApyModes
}

export const MultiChartsFallback = ({ apyMode }: Props) => {
  const vaults: BackendVault[] = [
    { address: '0x0', network: 'ethereum', name: 'Select' },
    { address: '0x1', network: 'ethereum', name: 'Your' },
    { address: '0x2', network: 'ethereum', name: 'Vault' },
  ] as unknown as BackendVault[]
  const multiChartDataMock = getMultiChartDataMock()

  return (
    <>
      <PanelContentWrapper>
        <MultiApyHistoryChartHeader apyMode={apyMode} />
        <FallbackWrapper showInfo>
          <MultiApyChart
            vaults={vaults}
            benchmarks={[]}
            multiChartData={multiChartDataMock}
            apyMode={apyMode}
            excludeRewards={true}
            zoomable
          />
        </FallbackWrapper>
      </PanelContentWrapper>
      <PanelContentWrapper>
        <MultiTvlHistoryChartHeader />
        <FallbackWrapper>
          <MultiTvlChart vaults={vaults} benchmarks={[]} multiChartData={multiChartDataMock} zoomable />
        </FallbackWrapper>
      </PanelContentWrapper>
    </>
  )
}

interface FallbackWrapperProps {
  children: ReactNode
  showInfo?: boolean
}

const FallbackWrapper = ({ children, showInfo }: FallbackWrapperProps) => {
  return (
    <div className="relative [clip-path:inset(0_round_1rem)]">
      {children}
      <BlurOverlay className="absolute inset-0 flex flex-col items-center justify-center p-4 text-center">
        {showInfo && (
          <>
            <ArrowIcon className="repeat-infinite animate-floating-arrow" size={16} />
            <span className="block font-bold text-2xl text-text-primary leading-6">
              Select vaults
              <br />
              to explore
            </span>
          </>
        )}
      </BlurOverlay>
    </div>
  )
}

function getMultiChartDataMock(): MultiChartDataGroup {
  const mergedData = [...MULTI_APY_CHART_DATA_MOCK, ...MULTI_TVL_CHART_DATA_MOCK]

  return {
    highResolution: mergedData,
    daily: mergedData,
  }
}
