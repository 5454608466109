import { useEffect, useState } from 'react'

import { ShareAppleIcon, ShareIcon } from '@/components/atoms/Icons'
import type { IconSize } from '@/components/atoms/Icons/IconBase'
import { Tooltip, TooltipContent } from '@/components/atoms/Tooltip'
import { sharePage } from '@/utils'

interface ShareButtonProps {
  tooltipText?: string
  iconSize?: IconSize
}

export const ShareButton = ({ tooltipText = 'Share', title, text, url, iconSize }: ShareButtonProps & ShareData) => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null)
  const [copied, setIsCopied] = useState<boolean>(false)
  const shareButtonTooltip = copied ? 'Link copied' : tooltipText

  const handleShareClick = async () => {
    const { isShareApiSupported } = await sharePage({ title, text, url })
    if (!isShareApiSupported) {
      setIsCopied(true)
      if (timer) {
        clearTimeout(timer)
      }
      setTimer(setTimeout(() => setIsCopied(false), 2000))
    }
  }

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [timer])

  return (
    <Tooltip className="relative flex items-center justify-center" onClick={handleShareClick}>
      {getShareIcon(iconSize)}
      <TooltipContent>{shareButtonTooltip}</TooltipContent>
    </Tooltip>
  )
}

function getShareIcon(iconSize?: IconSize) {
  const isAppleDevice = /Mac|iPhone|iPad|Macintosh/.test(navigator.userAgent)
  if (isAppleDevice) {
    return <ShareAppleIcon size={iconSize} />
  }
  return <ShareIcon size={iconSize} />
}
