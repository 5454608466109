export const SECOND_IN_MILLISECONDS = 1000
export const MINUTE_IN_MILLISECONDS = 60 * 1000
export const HOUR_IN_SECONDS = 3600
export const DAY_IN_SECONDS = 24 * HOUR_IN_SECONDS
export const YEAR_IN_SECONDS = 365 * DAY_IN_SECONDS
export const WEEK_IN_SECONDS = 7 * Number(DAY_IN_SECONDS)
export const MONTH_IN_SECONDS = 30 * DAY_IN_SECONDS

export const YEAR_IN_DAYS = 365

export const ONE_IN_BPS = 10_000n

export const RAY = 1e27
export const RAY_BIG_INT = 10n ** 27n
export const RAY_EXPONENT = 27

export const CHAINLINK_PRECISION = 10 ** 8

export const MAX_UINT256 = 115792089237316195423570985008687907853269984665640564039457584007913129639935n
