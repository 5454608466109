import { Button } from '@/components/atoms/Button'
import { Panel } from '@/components/atoms/Panel'
import { Page } from '@/components/molecules'
import { VAULTS_FYI_URL } from '@/constants'
import { usePageTracking } from '@/hooks'

function TermsOfServicePage() {
  usePageTracking()

  return (
    <Page title="Terms of Service">
      <Panel className="prose prose-sm max-w-full text-base">
        <h1>Terms of Service</h1>

        <h2>Disclaimer</h2>
        <p>
          This tool is being made available for educational purposes only and should not be used for any other purpose.
          The information contained herein does not constitute and should not be construed as an offering of advisory
          services or an offer to sell or solicitation to buy any securities or related financial instruments in any
          jurisdiction.
        </p>
        <p>
          Certain information contained herein is based on or derived from information provided by independent
          third-party sources. Wallfacer Labs believes that the sources from which such information has been obtained
          are reliable; however, it cannot guarantee the accuracy of such information and has not independently verified
          the accuracy or completeness of such information or the assumptions on which such information is based.
        </p>
        <p>
          We use cookies and similar technologies to collect information from your device, such as your IP address and
          the pages you’ve visited. We use this data to help us to better understand our audiences and to improve
          vaults.fyi.
        </p>

        <h2>Terms</h2>
        <p>
          By accessing the website at{' '}
          <Button variant="text" className="underline" asChild>
            <a href={VAULTS_FYI_URL} target="_blank" rel="noopener noreferrer">
              {VAULTS_FYI_URL}
            </a>
          </Button>
          , you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that
          you are responsible for compliance with any applicable local laws. If you do not agree with any of these
          terms, you are prohibited from using or accessing this site. The materials contained in this website are
          protected by applicable copyright and trademark law.
        </p>

        <h2>Use License</h2>
        <p>
          Permission is granted to temporarily download one copy of the materials (information or software) on
          Vault.fyi’s website for personal, non-commercial transitory viewing only. This is the grant of a license, not
          a transfer of title, and under this license, you may not:
        </p>
        <ul>
          <li>Modify or copy the materials</li>
          <li>
            Use the materials for any commercial purpose, or for any public display (commercial or non-commercial)
          </li>
          <li>Attempt to decompile or reverse engineer any software contained on vaults.fyi’s website</li>
          <li>Remove any copyright or other proprietary notations from the materials</li>
          <li>Transfer the materials to another person or "mirror" the materials on any other server.</li>
        </ul>
        <p>
          This license shall automatically terminate if you violate any of these restrictions and may be terminated by
          vaults.fyi at any time. Upon terminating your viewing of these materials or upon the termination of this
          license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
        </p>

        <h2>Disclaimer</h2>
        <p>
          The materials on vaults.fyi’s website are provided on an 'as is' basis. vaults.fyi makes no warranties,
          expressed or implied, and hereby disclaims and negates all other warranties including, without limitation,
          implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of
          intellectual property or other violation of rights.
        </p>
        <p>
          Further, vaults.fyi does not warrant or make any representations concerning the accuracy, likely results, or
          reliability of the use of the materials on its website or otherwise relating to such materials or on any sites
          linked to this site.
        </p>

        <h2>Limitations</h2>
        <p>
          In no event shall vaults.fyi or its suppliers be liable for any damages (including, without limitation,
          damages for loss of data or profit, or due to business interruption) arising out of the use or inability to
          use the materials on vaults.fyi’s website, even if vaults.fyi or a vaults.fyi authorized representative has
          been notified orally or in writing of the possibility of such damage.{' '}
        </p>
        <p>
          Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for
          consequential or incidental damages, these limitations may not apply to you.
        </p>

        <h2>Accuracy of Materials</h2>
        <p>
          The materials appearing on vaults.fyi’s website could include technical, typographical, or photographic
          errors. vaults.fyi does not warrant that any of the materials on its website are accurate, complete or
          current. vaults.fyi may make changes to the materials contained on its website at any time without notice.
          However, vaults.fyi does not make any commitment to update the materials.
        </p>

        <h2>Links</h2>
        <p>
          vaults.fyi has not reviewed all of the sites linked to its website and is not responsible for the contents of
          any such linked site. The inclusion of any link does not imply endorsement by vaults.fyi of the site. Use of
          any such linked website is at the user's own risk.
        </p>

        <h2>Modifications</h2>
        <p>
          vaults.fyi may revise these terms of service for its website at any time without notice. By using this website
          you are agreeing to be bound by the then current version of these terms of service.
        </p>

        <h2>Governing Law</h2>
        <p>
          These terms and conditions are governed by and construed in accordance with the laws of the Cayman Islands and
          you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
        </p>

        <h2>Personal data</h2>
        <p>
          We use cookies and similar technologies to collect information from your device, such as your IP address and
          the pages you’ve visited. We use this data to help us to better understand our audiences and to improve
          vaults.fyi. By accessing the website at{' '}
          <Button variant="text" className="underline" asChild>
            <a href={VAULTS_FYI_URL} target="_blank" rel="noopener noreferrer">
              {VAULTS_FYI_URL}
            </a>
          </Button>
          , you agree to this.
        </p>
      </Panel>
    </Page>
  )
}

export const Component = TermsOfServicePage
