import aave from '../images/protocols/aave.svg'
import agave from '../images/protocols/agave.png'
import ajna from '../images/protocols/ajna.svg'
import angle from '../images/protocols/angle.svg'
import ankr from '../images/protocols/ankr.svg'
import arcadia from '../images/protocols/arcadia.png'
import backed from '../images/protocols/backed.svg'
import coinbase from '../images/protocols/coinbase.svg'
import compound from '../images/protocols/compound.svg'
import convex from '../images/protocols/convex.png'
import ethena from '../images/protocols/ethena.png'
import etherfi from '../images/protocols/etherfi.svg'
import ethx from '../images/protocols/ethx.png'
import euler from '../images/protocols/euler.png'
import frax from '../images/protocols/frax.svg'
import gearbox from '../images/protocols/gearbox.png'
import hop from '../images/protocols/hop.svg'
import instadapp from '../images/protocols/instadapp.svg'
import inverse from '../images/protocols/inverse.png'
import lido from '../images/protocols/lido.svg'
import maker from '../images/protocols/maker.svg'
import maple from '../images/protocols/maple.png'
import matrixdock from '../images/protocols/matrixdock.svg'
import mev from '../images/protocols/mev.svg'
import moonwell from '../images/protocols/moonwell.png'
import morpho from '../images/protocols/morpho.svg'
import mountain from '../images/protocols/mountain.svg'
import notional from '../images/protocols/notional.png'
import oeth from '../images/protocols/oeth.svg'
import ondo from '../images/protocols/ondo.png'
import openeden from '../images/protocols/openeden.svg'
import origin from '../images/protocols/origin.svg'
import overnight from '../images/protocols/overnight.png'
import pooltogether from '../images/protocols/pooltogether.svg'
import rocketPool from '../images/protocols/rocketPool.png'
import seamless from '../images/protocols/seamless.png'
import silo from '../images/protocols/silo.svg'
import sky from '../images/protocols/sky.png'
import spark from '../images/protocols/spark.svg'
import stakewise from '../images/protocols/stakewise.svg'
import stargate from '../images/protocols/stargate.svg'
import swell from '../images/protocols/swell.svg'
import truefi from '../images/protocols/truefi.svg'
import yearn from '../images/protocols/yearn.svg'
import yeth from '../images/protocols/yeth.svg'

export function getProtocolImage(protocol: string) {
  switch (protocol.toLowerCase()) {
    case 'aave':
      return aave
    case 'agave':
      return agave
    case 'ajna':
      return ajna
    case 'angle':
      return angle
    case 'ankr':
      return ankr
    case 'arcadia':
      return arcadia
    case 'backed':
      return backed
    case 'coinbase':
      return coinbase
    case 'compound':
      return compound
    case 'convex':
      return convex
    case 'ethena':
      return ethena
    case 'ether.fi':
      return etherfi
    case 'ethx':
      return ethx
    case 'euler':
      return euler
    case 'frax':
      return frax
    case 'gearbox':
      return gearbox
    case 'hop':
      return hop
    case 'instadapp':
      return instadapp
    case 'inverse finance':
      return inverse
    case 'lido':
      return lido
    case 'maker':
      return maker
    case 'maple':
      return maple
    case 'matrixdock':
      return matrixdock
    case 'mev':
      return mev
    case 'moonwell':
      return moonwell
    case 'morpho':
      return morpho
    case 'mountain':
      return mountain
    case 'notional':
      return notional
    case 'oeth':
      return oeth
    case 'ondo':
      return ondo
    case 'open eden':
      return openeden
    case 'overnight finance':
      return overnight
    case 'pooltogether':
      return pooltogether
    case 'rocket pool':
      return rocketPool
    case 'seamless':
      return seamless
    case 'silo':
      return silo
    case 'sky':
      return sky
    case 'spark':
      return spark
    case 'stakewise':
      return stakewise
    case 'stargate':
      return stargate
    case 'swell':
      return swell
    case 'truefi':
      return truefi
    case 'yearn':
      return yearn
    case 'yeth':
      return yeth
    case 'origin':
      return origin
    default:
  }
}
