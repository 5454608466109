import type { Asset } from '@vaultsfyi/common'
import { useMemo } from 'react'
import { generatePath } from 'react-router'

import { routes } from '@/config'
import { BN_ONE_IN_PERCENT } from '@/constants'
import { formatNumber, formatUnits } from '@/utils/valueUtils'

const LIQUID_ASSETS_PRECISION = 100000n

interface VaultTrackingParamsProps {
  address: string
  network: string
  name: string
  asset: Asset
  protocolName: string | undefined
  liquidity: string | undefined
  tvl: string | undefined
  rawYieldInToken?: number
}

export const useVaultTrackingParams = ({
  address,
  network,
  name,
  asset,
  protocolName,
  liquidity,
  tvl,
  rawYieldInToken,
}: VaultTrackingParamsProps) => {
  const trackingParams = useMemo(() => {
    return {
      Name: name,
      Protocol: protocolName ?? 'n/a',
      Vaults_URL: generatePath(routes.vaultPreview, { chainName: network, address }),
      TVL: tvl ? `${formatNumber(formatUnits(BigInt(tvl), asset.decimals))} ${asset.symbol}` : 'n/a',
      BaseYield: rawYieldInToken ? `${formatNumber(rawYieldInToken)} ${asset.symbol}` : 'n/a',
      Liquid:
        liquidity && tvl
          ? `${formatNumber(
              Number((BigInt(liquidity) * BN_ONE_IN_PERCENT * LIQUID_ASSETS_PRECISION) / BigInt(tvl)) /
                Number(LIQUID_ASSETS_PRECISION)
            )}%`
          : 'n/a',
      Token: asset.symbol,
      Chain: network,
    }
  }, [address, asset.decimals, asset.symbol, liquidity, name, network, protocolName, rawYieldInToken, tvl])
  return trackingParams
}
