import * as Sentry from '@sentry/react'
import type { ReactNode } from 'react'
import { Fragment } from 'react'
import { isRouteErrorResponse, useRouteError } from 'react-router'

import { NotFoundPage } from '../NotFoundPage'

import bankEmoji from '/images/emojis/bankEmoji.png'
import flameEmoji from '/images/emojis/flameEmoji.png'

import { Image } from '@/components/atoms/Image'
import { Page } from '@/components/molecules'
import { Footer, Header } from '@/components/organisms'

class NotFoundError extends Response {
  constructor() {
    super('NotFound', { status: 404 })
  }
}

function ReThrow(): null {
  const error = useRouteError()
  throw error
}

export const ErrorPage = () => {
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <NotFoundPage />
    } else {
      console.error(error)
    }
  }

  if (error instanceof NotFoundError) {
    return <NotFoundPage />
  }

  if (error instanceof Error && (error as Error).message === 'Vault not found') {
    return (
      <ErrorPageContent>
        <p className="text-xl">Vault not found</p>
      </ErrorPageContent>
    )
  }

  return (
    <ErrorPageContent>
      <Sentry.ErrorBoundary>
        <ReThrow />
      </Sentry.ErrorBoundary>
    </ErrorPageContent>
  )
}

interface ErrorPageContentProps {
  children: ReactNode
}

const ErrorPageContent = ({ children }: ErrorPageContentProps) => {
  return (
    <Fragment>
      <Header />
      <Page title="Error">
        <div className="flex flex-col items-center gap-4 py-16 text-center">
          <div className="relative mb-4">
            <Image className="block size-20 rounded-none" src={bankEmoji} loading="lazy" />
            <Image
              className="repeat-infinite absolute bottom-0 left-0 size-8 origin-center-bottom animate-error-flame rounded-none"
              src={flameEmoji}
              loading="lazy"
            />
            <Image
              className="repeat-infinite absolute top-0 right-0 size-6 origin-center-bottom animate-error-flame rounded-none"
              src={flameEmoji}
              loading="lazy"
            />
          </div>
          <h1 className="text-3xl leading-none sm:text-4xl">Oops!</h1>
          <p className="font-bold text-xl">
            Yield source is missing...
            <br />
            Try one more time!
          </p>
          {children}
        </div>
      </Page>
      <Footer />
    </Fragment>
  )
}
