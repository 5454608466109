import { TableCell, type TableCellProps } from '@/components/atoms/Table'
import type { BackendVault } from '@/types'
import { Line, LineChart, YAxis } from 'recharts'

interface BookmarkCellProps {
  chartData: BackendVault['sparklineChartData']
  excludeRewards?: boolean
}

export const SparklineChartCell = ({ chartData, excludeRewards, ...props }: BookmarkCellProps & TableCellProps) => {
  if (chartData.length === 0) {
    return (
      <TableCell data-cell="Sparkline chart" align="right" {...props}>
        <span className="text-center font-bold text-text-secondary">N/A</span>
      </TableCell>
    )
  }

  const chartApyKey = excludeRewards ? 'apy' : 'apyWithRewards'
  // green if apy increased or stayed the same, red if decreased
  const chartColor =
    chartData[0][chartApyKey] <= chartData[chartData.length - 1][chartApyKey] ? 'hsl(var(--green))' : 'hsl(var(--red))'

  return (
    <TableCell data-cell="Sparkline chart" align="right" {...props}>
      <LineChart width={64} height={30} data={chartData}>
        <YAxis hide domain={['dataMin', 'dataMax']} />
        <Line
          type="monotone"
          dataKey={chartApyKey}
          stroke={chartColor}
          strokeWidth={2}
          dot={false}
          isAnimationActive={false}
        />
      </LineChart>
    </TableCell>
  )
}
