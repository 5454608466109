import { useEffect, useState } from 'react'

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/atoms/Accordion'
import { ArrowIcon } from '@/components/atoms/Icons'
import { SelectNetworkFilter, SelectProtocolsFilter, SelectTagFilter } from '@/components/molecules'
import { SelectTvlRange } from '@/components/molecules/filters/SelectTvlRange'
import { OPTIMIZER_FILTERS_OPEN, OPTIMIZER_FILTERS_TOUCHED } from '@/constants'
import type { BackendVault } from '@/types'
import { getLocalStorageValue, setLocalStorageValue, trackInvestmentFiltersToggleEvent } from '@/utils'
import { cn } from '@/utils/cn'

export interface InvestmentPickerFiltersProps {
  vaults: Array<BackendVault>
}

export const InvestmentPickerFilters = ({ vaults }: InvestmentPickerFiltersProps) => {
  const [isFiltersOpen, setFiltersOpen] = useState<boolean>(Boolean(getLocalStorageValue(OPTIMIZER_FILTERS_OPEN)))
  const [isFiltersTouched, setFiltersTouched] = useState<boolean>(
    Boolean(getLocalStorageValue(OPTIMIZER_FILTERS_TOUCHED))
  )

  const FILTERS_ACCORDION_VALUE = 'Investment picker filters'

  const handleFiltersOpen = () => {
    setFiltersOpen((prevState) => !prevState)
    trackInvestmentFiltersToggleEvent(isFiltersOpen)
    setLocalStorageValue(OPTIMIZER_FILTERS_TOUCHED, true)
    setFiltersTouched?.(true)
  }

  useEffect(() => {
    setLocalStorageValue(OPTIMIZER_FILTERS_OPEN, isFiltersOpen)
  }, [isFiltersOpen])

  return (
    <Accordion type="multiple" defaultValue={isFiltersOpen ? [FILTERS_ACCORDION_VALUE] : []}>
      <AccordionItem value={FILTERS_ACCORDION_VALUE} className="bg-darkGray/12 ">
        <AccordionContent>
          <div className="grid grid-cols-1 gap-2 px-4 py-2 md:grid-cols-2">
            <SelectTagFilter vaults={vaults} />
            <SelectProtocolsFilter vaults={vaults} />
            <SelectNetworkFilter vaults={vaults} />
            <SelectTvlRange />
          </div>
        </AccordionContent>
        <AccordionTrigger
          className={cn(
            'group flex h-7 w-full items-center justify-center border-gray border-t bg-white px-8 py-0.5 text-center hover:text-primary focus-visible:text-primary',
            !isFiltersTouched && 'repeat-infinite animate-button-glow'
          )}
          onClick={handleFiltersOpen}
        >
          {isFiltersOpen ? 'Hide' : 'Show'} filters{' '}
          <ArrowIcon className="group-data-[state=open]:-scale-y-100 transition-transform" direction="down" />
        </AccordionTrigger>
      </AccordionItem>
    </Accordion>
  )
}
