import { EditNoteIcon } from '@/components/atoms/Icons'
import { Tooltip, TooltipContent } from '@/components/atoms/Tooltip'
import { useNavigate } from 'react-router'

export const EditVaultButton = ({ href }: { href: string }) => {
  const navigate = useNavigate()

  return (
    <Tooltip onClick={() => navigate(href)}>
      <EditNoteIcon size={24} />
      <TooltipContent>
        <p>Edit vault data</p>
      </TooltipContent>
    </Tooltip>
  )
}
