import type { MenuLinkBasicProps, MenuLinksBasicProps } from '.'
import { MenuLink } from '.'

import { Button } from '@/components/atoms/Button'
import { XIcon } from '@/components/atoms/Icons'
import { NavigationMenu, NavigationMenuList, NavigationMenuViewport } from '@/components/atoms/Navigation'
import { VAULTS_FYI_TWITTER_URL } from '@/constants'
import { trackMenuLinkEvent } from '@/utils'
import { MenuGroup } from './MenuGroup'

const headerButtonStyles =
  'flex justify-center items-center min-w-8 size-8 text-text-primary hover:text-primary focus-visible:text-primary'

interface Props {
  links: Array<MenuLinkBasicProps | MenuLinksBasicProps>
}

export const DesktopNavigation = ({ links }: Props) => {
  return (
    <div className="hidden items-center gap-2 lg:flex">
      <NavigationMenu>
        <NavigationMenuList className="flex items-center gap-2">
          {links.map((link, index) => {
            if ('links' in link) {
              return <MenuGroup key={index} {...link} />
            }
            return <MenuLink key={index} {...link} />
          })}
        </NavigationMenuList>
        <NavigationMenuViewport />
      </NavigationMenu>
      <div className="hidden flex-wrap items-center gap-2 md:flex">
        <Button
          variant="default"
          className={headerButtonStyles}
          onClick={() => trackMenuLinkEvent('Twitter Link')}
          asChild
        >
          <a href={VAULTS_FYI_TWITTER_URL} target="_blank" rel="noopener noreferrer">
            <XIcon />
          </a>
        </Button>
      </div>
    </div>
  )
}
