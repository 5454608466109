import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router'

import { getSubdomain } from '@/utils/subdomain/getSubdomain'

export const usePageTracking = () => {
  const { pathname } = useLocation()
  const subdomain = getSubdomain()?.prefix ?? 'N/A'

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: pathname, subdomain })
    mixpanel.track('Page View', {
      Pathname: pathname,
      Subdomain: subdomain,
    })
  }, [pathname, subdomain])
}
