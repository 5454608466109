import aave from '../images/tokens/aave.svg'
import ageth from '../images/tokens/ageth.png'
import ausd from '../images/tokens/ausd.png'
import bal from '../images/tokens/bal.png'
import bat from '../images/tokens/bat.png'
import btc from '../images/tokens/btc.png'
import cbbtc from '../images/tokens/cbbtc.png'
import cbeth from '../images/tokens/cbeth.svg'
import comp from '../images/tokens/comp.png'
import crvusd from '../images/tokens/crvusd.png'
import dai from '../images/tokens/dai.png'
import dola from '../images/tokens/dola.png'
import eth from '../images/tokens/eth.svg'
import eura from '../images/tokens/eura.png'
import eurc from '../images/tokens/eurc.png'
import eurs from '../images/tokens/eurs.svg'
import eusd from '../images/tokens/eusd.png'
import ezeth from '../images/tokens/ezeth.png'
import frax from '../images/tokens/frax.png'
import frxeth from '../images/tokens/frxeth.png'
import gho from '../images/tokens/gho.png'
import gmx from '../images/tokens/gmx.svg'
import gusd from '../images/tokens/gusd.png'
import lbtc from '../images/tokens/lbtc.png'
import ldo from '../images/tokens/ldo.svg'
import link from '../images/tokens/link.png'
import lusd from '../images/tokens/lusd.png'
import mai from '../images/tokens/mai.png'
import matic from '../images/tokens/matic.png'
import mkusd from '../images/tokens/mkusd.png'
import mseth from '../images/tokens/mseth.svg'
import ousg from '../images/tokens/ousg.png'
import pxeth from '../images/tokens/pxeth.png'
import pyusd from '../images/tokens/pyusd.png'
import rdnt from '../images/tokens/rdnt.svg'
import rep from '../images/tokens/rep.png'
import reth from '../images/tokens/reth.png'
import rseth from '../images/tokens/rseth.png'
import rusd from '../images/tokens/rusd.svg'
import steth from '../images/tokens/steth.png'
import susd from '../images/tokens/susd.png'
import susde from '../images/tokens/susde.png'
import sushi from '../images/tokens/sushi.png'
import tbtc from '../images/tokens/tbtc.png'
import tusd from '../images/tokens/tusd.png'
import uni from '../images/tokens/uni.png'
import unknownToken from '../images/tokens/unknownToken.svg'
import usd0 from '../images/tokens/usd0.png'
import usda from '../images/tokens/usda.png'
import usdbc from '../images/tokens/usdbc.svg'
import usdc from '../images/tokens/usdc.png'
import usde from '../images/tokens/usde.png'
import usdm from '../images/tokens/usdm.svg'
import usdp from '../images/tokens/usdp.png'
import usds from '../images/tokens/usds.png'
import usdt from '../images/tokens/usdt.png'
import wbtc from '../images/tokens/wbtc.png'
import weeth from '../images/tokens/weeth.png'
import weeths from '../images/tokens/weeths.png'
import weth from '../images/tokens/weth.png'
import wm from '../images/tokens/wm.png'
import wmatic from '../images/tokens/wmatic.png'
import wsteth from '../images/tokens/wsteth.png'
import yfi from '../images/tokens/yfi.png'
import zrx from '../images/tokens/zrx.png'

export const getTokenImage = (symbol: string) => {
  switch (symbol.toLowerCase()) {
    case 'aave':
      return aave
    case 'ageth':
      return ageth
    case 'ausd':
      return ausd
    case 'bal':
      return bal
    case 'bat':
      return bat
    case 'btc':
    case 'btc.b':
      return btc
    case 'cbbtc':
      return cbbtc
    case 'cbeth':
      return cbeth
    case 'comp':
      return comp
    case 'crvusd':
      return crvusd
    case 'dai':
    case 'dai.e':
      return dai
    case 'dola':
      return dola
    case 'eth':
      return eth
    case 'eura':
      return eura
    case 'eurc':
      return eurc
    case 'eurs':
      return eurs
    case 'eusd':
      return eusd
    case 'ezeth':
      return ezeth
    case 'frax':
      return frax
    case 'frxeth':
      return frxeth
    case 'gho':
      return gho
    case 'gmx':
      return gmx
    case 'gusd':
      return gusd
    case 'lbtc':
      return lbtc
    case 'ldo':
      return ldo
    case 'link':
      return link
    case 'lusd':
      return lusd
    case 'mai':
      return mai
    case 'matic':
      return matic
    case 'mkusd':
      return mkusd
    case 'mseth':
      return mseth
    case 'ousg':
      return ousg
    case 'pxeth':
      return pxeth
    case 'pyusd':
      return pyusd
    case 'rdnt':
      return rdnt
    case 'rep':
      return rep
    case 'reth':
      return reth
    case 'rseth':
      return rseth
    case 'rusd':
      return rusd
    case 'steth':
      return steth
    case 'susd':
      return susd
    case 'susde':
      return susde
    case 'sushi':
      return sushi
    case 'tbtc':
      return tbtc
    case 'tusd':
      return tusd
    case 'uni':
      return uni
    case 'usd0':
      return usd0
    case 'usda':
      return usda
    case 'usdbc':
      return usdbc
    case 'usdc':
    case 'usdc.e':
      return usdc
    case 'usde':
      return usde
    case 'usdm':
      return usdm
    case 'usdp':
      return usdp
    case 'usds':
      return usds
    case 'usdt':
    case 'usdt.e':
      return usdt
    case 'wbtc':
      return wbtc
    case 'weeth':
      return weeth
    case 'weeths':
      return weeths
    case 'weth':
    case 'weth.e':
      return weth
    case 'wm':
      return wm
    case 'wmatic':
      return wmatic
    case 'wsteth':
      return wsteth
    case 'yfi':
      return yfi
    case 'zrx':
      return zrx
    default:
      return unknownToken
  }
}
